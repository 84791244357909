<template>
  <ValidationProvider
    tag="div"
    :vid="vid"
    :rules="rules"
    :name="name"
    v-slot="{ errors, required, ariaInput, ariaMsg }"
    :bails="false"
  >
    <label
      @click="$refs.input.focus()"
      :for="id"
      :class="{ error: errors[0] }"
      v-if="!hideLabel"
    >
      <span>{{ name }}</span>
      <span class="error">{{ required ? " *" : "" }}</span>
    </label>
    <span v-if="rows">
      <textarea
        :disabled="disabled"
        class="form-control"
        :class="formControlSizeClass"
        :onpaste="'return ' + onpaste"
        :id="id"
        :type="type"
        :placeholder="placeholder"
        ref="input"
        v-model="innerValue"
        v-bind="ariaInput"
        @keydown.tab="tabPressed"
        :readonly="readonly"
        :autocomplete="autocomplete"
        @change="onChange"
        :rows="rows"
        :multiple="multiple"
      />
    </span>
    <span v-else>
      <span v-if="mask">
        <the-mask
          :disabled="disabled"
          class="form-control"
          :class="formControlSizeClass"
          :onpaste="'return ' + onpaste"
          :id="id"
          :type="type"
          :placeholder="placeholder"
          ref="input"
          :mask="mask"
          v-model="innerValue"
          v-bind="ariaInput"
          @keydown.tab="tabPressed"
          :readonly="readonly"
          :autocomplete="autocomplete"
          @change="onChange"
          :multiple="multiple"
        ></the-mask>
      </span>
      <span v-else>
        <input
          :disabled="disabled"
          class="form-control"
          :class="formControlSizeClass"
          :onpaste="'return ' + onpaste"
          :id="id"
          :type="type"
          :placeholder="placeholder"
          ref="input"
          v-model="innerValue"
          v-bind="ariaInput"
          @keydown.tab="tabPressed"
          :readonly="readonly"
          :autocomplete="autocomplete"
          @change="onChange"
          :multiple="multiple"
        />
      </span>
    </span>
    <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
      <li
        v-for="(error, index) in errors"
        :key="index"
        class="error"
        v-bind="ariaMsg"
      >
        {{ error }}
      </li>
    </ul>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { TheMask } from "vue-the-mask";

export default {
  name: "TextInput",
  // directives: { mask },
  components: {
    ValidationProvider,
    TheMask
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    onpaste: {
      type: String,
      default: "true"
    },
    vid: {
      type: String,
      default: undefined
    },
    id: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    mask: {
      type: String
    },
    placeholder: {
      type: String,
      default: ""
    },
    multiple: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text",
      validator(value) {
        return [
          "url",
          "text",
          "password",
          "tel",
          "search",
          "number",
          "email"
        ].includes(value);
      }
    },
    value: {
      type: null,
      default: ""
    },
    size: {
      type: String,
      default: "lg"
    },
    autocomplete: {
      type: String,
      default: "off"
    },
    rows: {
      type: String,
      default: null
    },
    hideLabel: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    innerValue: ""
  }),
  computed: {
    hasValue() {
      return !!this.innerValue;
    },
    formControlSizeClass() {
      return "form-control-" + this.size;
    }
  },
  watch: {
    innerValue(value) {
      this.$emit("input", value);
    },
    value(val) {
      if (val !== this.innerValue) {
        this.innerValue = val;
      }
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  methods: {
    tabPressed() {
      this.$emit("tab");
    },
    onChange(e) {
      this.$emit("change", e);
    }
  }
};
</script>
