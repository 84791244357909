<template>
  <div>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.editCustomerForm"
    />
    <div class="card">
      <div class="card-header">
        <div class="card-title mb-0">
          Edit Customer Profile Details
        </div>
      </div>
      <div class="card-body">
        <ValidationObserver ref="editCustomerForm">
          <form @submit.prevent="onSubmit">
            <AdminCustomerAddForm
              :customer="customer"
              :edit="true"
              :customerStatuses="customerStatuses"
              :usernameIsAvailable="
                !invalidUsername &&
                  this.customer.username &&
                  this.customer.username !== ''
              "
              :usernameIsNotAvailable="
                usernameChecked &&
                  !usernameAvailable &&
                  !this.customer.externalUserId
              "
            />
            <b-modal
              ref="confirm-email-modal"
              size="sm"
              hide-header-close
              header-class="border-0"
              footer-class="border-0"
              no-close-on-backdrop
            >
              <div class="d-block text-center">
                <h4 class="text-uppercase">Confirm Email Address Change</h4>
                <h5>
                  You have modified the email id for the customer, customer will
                  receive future communication on this email address. Please
                  confirm the changes.
                </h5>
              </div>
              <template v-slot:modal-footer="{ ok }">
                <b-button class="btn" variant="primary" @click="changeEmail(ok)"
                  >Save and confirm Changes</b-button
                >
                <b-button class="btn" variant="" @click="ok"
                  >Cancel changes</b-button
                >
              </template>
            </b-modal>
            <button type="submit" class="btn btn-lg btn-primary mt-4 mr-2">
              Save
            </button>
            <button
              type="button"
              class="btn btn-lg btn-primary mt-4 mr-2"
              @click="viewReservations"
            >
              View Customer’s Reservation
            </button>
            <button
              type="button"
              class="btn btn-lg btn-primary mt-4 mr-2"
              @click="resetPass"
              v-if="this.customer.userId && this.customer.verified"
            >
              Reset Password
            </button>
            <button
              type="button"
              class="btn btn-lg btn-primary mt-4 mr-2"
              @click="confirmAccount"
              v-if="this.customer.userId && !this.customer.verified"
            >
              Send Confirmation Email
            </button>
            <button
              class="btn btn-cancel btn-lg mt-4"
              @click="pushToSearch"
              type="button"
            >
              Cancel
            </button>
            <p class="mt-2">
              <span style="color: red;">*</span> Indicates a required field
            </p>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import AdminProfileService from "@/services/admin/AdminProfileService.js";
import { ValidationObserver } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import AdminCustomerAddForm from "@/components/admin/AdminCustomerAddForm.vue";
import states from "@/store/modules/states.js";
import ProfileService from "@/services/ProfileService.js";

export default {
  name: "AdminCustomerEdit",
  title: "Admin - Customer Edit",
  components: {
    ValidationObserver,
    FormErrorAlert,
    AdminCustomerAddForm
  },
  props: {
    customerId: String
  },
  data() {
    return {
      customer: {},
      prefixes: ["Mr", "Ms", "Mrs", "Dr", "Sir"],
      suffixes: ["Jr", "Sr", "III", "Esq", "MD", "PhD"],
      errors: [],
      customerStatuses: [],
      currentEmail: null,
      emailChangeConfirmed: false,
      externalUserId: null,
      usernameAvailable: false,
      usernameChecked: false,
      loadingUsername: false,
      usernameTimeout: null
    };
  },
  watch: {
    "customer.username"() {
      clearTimeout(this.usernameTimeout);
      var self = this;
      this.usernameTimeout = setTimeout(() => {
        self.checkUsername(self.customer.username);
      }, 1000);
    }
  },
  computed: {
    states() {
      return states;
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    invalidUsername() {
      return this.customer.username
        ? this.customer.username === ""
          ? false
          : !(this.usernameAvailable && this.usernameChecked)
        : false;
    }
  },
  methods: {
    async checkUsername(username) {
      if (
        username &&
        username.match(/^[A-Za-z0-9-_=.]+$/) &&
        username.length > 7 &&
        username.length < 21
      ) {
        const profileService = new ProfileService(this.tenantId);
        this.loadingUsername = true;
        this.usernameAvailable = false;
        this.usernameChecked = false;
        const response = await profileService.checkUsernameAvailability(
          username
        );
        this.usernameAvailable =
          response.statusCode === "Success" && response.data === true;
        this.usernameChecked = true;
        this.loadingUsername = false;
      } else {
        this.usernameAvailable = this.usernameChecked = false;
      }
    },
    viewReservations() {
      this.$router
        .push(`/admin/reservation-search?customerId=${this.customerId}`)
        .catch(() => {});
    },
    async loadCustomer() {
      let profileService = new AdminProfileService(this.tenantId);
      let loadedCustomerResponse = await profileService.getCustomerByIdAdmin(
        this.customerId
      );
      if (loadedCustomerResponse.statusCode == "Success") {
        const loadedCustomer = loadedCustomerResponse.data;
        this.externalUserId = loadedCustomer.user?.externalUserId;
        const {
          prefixName,
          firstName,
          middleName,
          lastName,
          suffixName,
          email,
          comments,
          customerStatus,
          userId,
          timezoneId,
          createdByAdmin,
          id
        } = loadedCustomer;
        this.currentEmail = email;
        this.customer = {
          prefixName,
          firstName,
          middleName,
          lastName,
          suffixName,
          email,
          comments,
          customerStatus,
          userId,
          timezoneId,
          createdByAdmin,
          id,
          verified: loadedCustomer.user?.verified,
          externalUserId: this.externalUserId
        };
        if (
          loadedCustomer.addresses.filter(x => x.addressTypeId === 1).length > 0
        ) {
          const address = loadedCustomer.addresses.filter(
            x => x.addressTypeId === 1
          )[0];
          const {
            id,
            street1,
            street2,
            city,
            state,
            zipcode,
            country
          } = address;
          this.customer = {
            ...this.customer,
            addressId: id,
            street1,
            street2,
            city,
            state: state.trim(),
            zipCode: zipcode.trim(),
            country
          };
        }
        if (
          loadedCustomer.customerPhoneNumbers.filter(
            x => x.isPrimary && x.phoneNumberTypeId === 1
          ).length > 0
        ) {
          const primary = loadedCustomer.customerPhoneNumbers.filter(
            x => x.isPrimary && x.phoneNumberTypeId === 1
          )[0];
          this.customer.primaryPhoneNumber = primary.number;
          this.customer.primaryPhoneNumberId = primary.id;
        }
        if (
          loadedCustomer.customerPhoneNumbers.filter(
            x => x.phoneNumberTypeId === 2
          ).length > 0
        ) {
          const alternate = loadedCustomer.customerPhoneNumbers.filter(
            x => x.phoneNumberTypeId === 2
          )[0];
          this.customer.alternatePhoneNumber = alternate.number;
          this.customer.alternatePhoneNumberId = alternate.id;
        }
        if (loadedCustomer.user && loadedCustomer.user.username) {
          this.customer.username = loadedCustomer.user.username;
        }
      }
    },
    async resetPass() {
      const profileService = new ProfileService(this.tenantId);
      const user = await profileService.forgotPassword(this.customer.username);
      if (user) {
        window.scrollTo(0, 0);
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-info",
          message:
            "Password reset submitted. If an email is associated with this username, a link will be sent to complete the password reset process.",
          layer: "admin"
        });
      }
    },
    async confirmAccount() {
      const profileService = new ProfileService(this.tenantId);
      await profileService.resendRegistrationCode(this.externalUserId);
      window.scrollTo(0, 0);
      this.$store.commit("alert/setErrorAlert", {
        type: "alert-info",
        message:
          "Account confirmation link resent. Previous link will not be valid...",
        layer: "admin"
      });
    },
    async loadCustomerStatuses() {
      const profileService = new AdminProfileService(this.tenantId);
      this.customerStatuses = await profileService.getCustomerStatuses();
    },
    changeEmail(ok) {
      ok();
      this.emailChangeConfirmed = true;
      this.onSubmit();
    },
    async onSubmit() {
      this.$refs.editCustomerForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.editCustomerForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.editCustomerForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          if (
            this.currentEmail !== this.customer.email &&
            !this.emailChangeConfirmed
          ) {
            this.$refs["confirm-email-modal"].show();
            return;
          }
          try {
            this.$store.commit("auth/setLoading", true);
            const profileService = new AdminProfileService(this.tenantId);
            this.customer.customerId = this.customerId;
            this.customer.state = this.customer.state.trim();
            this.customer.zipCode = this.customer.zipCode ?? null;
            this.customer.primaryPhoneNumber =
              this.customer.primaryPhoneNumber ?? null;
            this.customer.alternatePhoneNumber =
              this.customer.alternatePhoneNumber ?? null;

            const updatedCustomerResponse = await profileService.editCustomerProfileAdmin(
              this.customer
            );
            if (updatedCustomerResponse.statusCode == "Success") {
              const updatedCustomer = updatedCustomerResponse.data;
              this.$router.push("/admin/customer-search").catch(() => {});
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-success",
                message: `Customer ${updatedCustomer.firstName} ${updatedCustomer.lastName} was successfully updated`,
                layer: "admin"
              });
              this.emailChangeConfirmed = false;
            } else {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Error in updating customer, please try again",
                layer: "admin"
              });
            }
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          } finally {
            this.$store.commit("auth/setLoading", false);
          }
        }
      });
    },
    pushToSearch() {
      this.$router.push("/admin/customer-search").catch(() => {});
    }
  },
  mounted() {
    this.loadCustomer();
    this.loadCustomerStatuses();
  }
};
</script>
