<template>
  <div class="alert alert-danger alert-outline-coloured" role="alert">
    <div class="alert-icon">
      <i class="far fa-fw fa-bell"></i>
    </div>
    <div class="alert-message text-left">
      <ul class="mb-0" v-for="(error, errIndex) in errors" :key="errIndex">
        <li v-for="(val, valIndex) in error.value" :key="valIndex">
          <a class="alert-link" role="button" @click="goToError(errIndex)">{{
            val.substr(0, val.indexOf(" "))
          }}</a
          >{{ val.substr(val.indexOf(" ")) }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormErrorAlert",
  props: {
    errors: Array,
    formRef: Object
  },
  methods: {
    goToError(errIndex) {
      this.formRef.refs[this.errors[errIndex]["key"]].$el.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
  }
};
</script>

<style scoped>
.alert-outline-coloured .alert-message,
.alert-outline .alert-message {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: white;
  color: black;
}
.alert-link {
  text-decoration: underline !important;
  color: black;
}
</style>
